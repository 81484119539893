import { Component, OnInit, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Router } from "@angular/router";
import { ColorsService } from '../../../shared/colors/colors.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { UserService } from '../../../services/user.service';
import { LoggingService } from '../../../core/services/log.service';
import { AppService } from '../../../core/services/base.service';
import { SharedService } from '../../../shared/shared.service';
import swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { SellerService } from '../../../services/seller.service'

@Component({
  selector: 'table-expandable',
  styleUrls: ['./data-table.component.scss'],
  templateUrl: './data-table.component.html',
  encapsulation: ViewEncapsulation.None,
})

export class TableExpandableRows implements OnInit,OnDestroy {
  userList: any = [];
  userProfileList: any = [];
  userDetails: any = [];

  @ViewChild('dataTable') table: any;
  @ViewChild('table') tableUserDetails: any;
  sortProp: String = "";
  tableRecordNumbers:number;
  rows: any[] = [];
  expanded: any = {};
  expanded2: any = {};
  timeout: any;
  selected = [];
  underRows: any;
  msgTranslation;
  errorTranslation;
  errorMessage;
  reloadUserTableSub:Subscription;
  selectedUser : any
  queryParams: any;
  profile: any;
  merchantInfo: any;

  allStatus: any = [
    { "name": "Attivo" },
    { "name": "Bloccato" },
  ]

  my_messages = {
    'emptyMessage': '',
    'totalMessage': ''
  };
  _localSto = {idMerchant: ''}
  notifySelectedUsercChange : Subscription;

  constructor(public http: HttpClient,
    public appService: AppService,
    private router: Router,
    private logService: LoggingService,
    private translate: TranslateService,
    public colors: ColorsService,
    public settings: SettingsService,
    public sharedService: SharedService,
    private userService: UserService , 
    private sellerService : SellerService ) {
      this.tableRecordNumbers =  parseInt(localStorage.getItem('tableRecordNumbers'));
      this.translate.stream('error').subscribe((res : string) => { this.errorTranslation = res; });
      this.translate.stream('msg').subscribe((res : string) => { this.msgTranslation = res; });
      // Overrides default total/empty messages
      translate.stream("datatable_message.lista_totali_righe").subscribe((res : string ) => this.my_messages.totalMessage = res );	
      translate.stream("datatable_message.lista_vuota_righe").subscribe((res : string ) => this.my_messages.emptyMessage = res);	
  }

  ngOnInit() {
    this.profile = localStorage.getItem('profile');
    this.getAllUsers();
    this.sharedService.sortTableObserver.subscribe((name: any) => {
      this.sortProp = name;
      var that = this;
      setTimeout(function () {
        that.rows = [...that.rows]
      })
    })
    this.reloadUserTableSub = this.sharedService.reloadUserTableObserver.subscribe((queryParams: any) => {
      console.log( 'reloadUserTableObserver' , queryParams )
      // this.selectedUser = localStorage.getItem("selectedUser")
      // if( this.selectedUser != null ) {
      //   // this.searchEvent.emit({ "keyword": "this.chipFromKeyword", "searchFromSelect": "chipsFromSelectOnlyNames", "searchFromCheckbox": "this.checkboxes" })
      //   console.log('dovrei fare emit di search event' , this.selectedUser )
      //   this.getAllUsers(queryParams);
      // } else {
      //   this.getAllUsers(queryParams);
      // }
      this.getAllUsers(queryParams);
      if( this.table ) {
        this.table.offset = 0;
      }
    })
    this.notifySelectedUsercChange = this.sharedService.selectedUserChanged.subscribe((value) => {
      /*console.log( 'dovrei vederlo quando cambia il selectedUser' , value )
      this.getAllUsers()*/
    })
    if( localStorage.getItem('profile') != 'SUPERADMIN' && localStorage.getItem('profile') != 'RESELLER' ) {
      this.getMerchantInfo();
    }
  }

  getAllUsers(queryParams = null) {
    /*let selectedUser = JSON.parse(localStorage.getItem("selectedUser"))
    console.log( 'poppi' , selectedUser , queryParams)
    if (!selectedUser) {
      selectedUser = localStorage.getItem("username");
    } else {
      queryParams = 'idMerchant='+selectedUser.idMerchant
    }*/
    this.queryParams = queryParams
    this.userService.getAllUsers(queryParams).subscribe((res: any) => {
      this.userList = res.results;
      this.rows = this.userList;
    })
  }

  getCapitals() {
    for (var i = 0; i < this.rows.length; i++) {
      var fullname = this.rows[i].name.split(' ')
      this.rows[i]["capitals"] = fullname[0].charAt(0) + fullname[1].charAt(0)
    }
  }

  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      console.log('paged!', event);
    }, 100);
  }

  toggleExpandRowUser(row, expanded) {
    if (!expanded) {
      this.getUsersDetail(row.idUser, row);
    }
    this.tableUserDetails.rowDetail.toggleExpandRow(row);
  }

  toggleExpandFirstRow(row, expanded) {
    if (!expanded) {
      this.getUserProfiles(row.idUser, row);
      this.getUsersDetail(row.idUser, row);
    }
    this.table.rowDetail.toggleExpandRow(row);
  }

  getUsersDetail(idUser, row) {
    console.log( 'idUser' , idUser , ' row' , row )
    this.userService.getUserDetails(idUser).subscribe((res: any) => {
      row['userDetails'] = res;
    })
  }

  getUserProfiles(idUser, row) {
    row['userTableList'] = [];
    this.userService.getUserProfiles(idUser).subscribe((res: any) => {
      setTimeout(function () {
      row['userTableList'] = res.results;
      }, 100)
    })
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }
  
  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  
  statusSelectedEvent(event, row) {
    row.statusSelectOpened = false
    row.state = event.name;
    this.changeStatus({ idUser: row.idUser, state: row.state })
  }

  hideSelectEvent(row) {
    row.statusSelectOpened = false
  }

  toggleUserActivation(row) {
    let state = null;
    if (row.state == 'Attivo') {
      state = 'Bloccato';
    } else {
      state = 'Attivo';
    }
    this.changeStatus({ idUser: row.idUser, state })
  }

  changeStatus(status) {
    this.userService.changeStatus(status).subscribe((response: any) => {
      if (response.outcome.success === true) {
        this.getAllUsers(this.queryParams);
        if( this.table ) {
          this.table.offset = 0;
        }
      } else {
        swal.fire("Error", this.settings.manageErrorMsg(response.outcome), "error");
      }
    },
      (error) => {
        this.errorMessage = this.errorTranslation.generic_error + " " + this.errorTranslation.try_again;
        swal.fire("Error", this.errorMessage, "error");
      })
  }

  defineHeight() {
    return 0;
  }

  canEdit(rowData) {
    if( this.profile == 'SUPERADMIN' || this.profile=='RESELLER' || (this.merchantInfo && this.merchantInfo.state != 'INACTIVE') ) {
      return true
    } else {
      return false
    }
  }

  hasActionToShow( data ) {
    if( this.canEdit(data) ) {
      return true;
    } else {
      return false;
    }
  }

  getMerchantInfo() {
    this.sellerService.getSellerDetails( localStorage.getItem('idMerchant') ).subscribe((res) => {
      this.merchantInfo = res;
    })
  }

  ngOnDestroy(){
    this.reloadUserTableSub.unsubscribe()
    this.notifySelectedUsercChange.unsubscribe()
  }
}
