import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer } from '@angular/forms';
import { Location } from '@angular/common';
import { ColorsService } from '../../../shared/colors/colors.service';
// import { HttpClient } from 'selenium-webdriver/http';
import { SettingsService } from '../../../core/settings/settings.service';
import { ProfileService } from '../../../services/profile.service';
import { HttpClient } from '@angular/common/http';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { RouterExtService } from '../../../core/services/router.ext.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileComponent implements OnInit {
  profilesData :any =[];

  profileForm = this.fb.group({
    name: [''],
    surname: [''],
    username: [''],
    email: ['', Validators.compose([Validators.required,Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])],
    profile: [''],
    telephone: [''],

  });
  msgTranslation;
  errorTranslation;
  errorMessage;
  myData;
  logoPath;
  profile;
  originalProfilesData: {};

  constructor( private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private location: Location,
    public colors: ColorsService,
    public http: HttpClient,
    public settings: SettingsService,
    private profileService: ProfileService,
    private router: Router,
    private routerExtService: RouterExtService) {
      this.profile = localStorage.getItem("profile");
      this.translate.stream('error').subscribe((res : string) => { this.errorTranslation = res; });
      this.translate.stream('msg').subscribe((res : string) => { this.msgTranslation = res; });
    }

  ngOnInit() {
    this.profileService.getProfilesData().subscribe((res:any)=>{
      this.myData = res;
      this.profileForm.setValue ({
        name: res.name,
        surname: res.surname,
        username: res.username,
        email: res.email,
        profile: res.profile,
        telephone: res.telephone,
      })
     })

     this.logoPath = localStorage.getItem('logo')

       if(this.profile == 'SUPERADMIN' || this.profile == 'RESELLER' || this.profile == 'RESPONSIBLE' || this.profile == 'MANAGER' ) {
         this.logoPath = null;
       }

    var disabledInputs = [
      "name",
      "surname",
      "username",
      // "email",
      // "phone",
      "profile",
    ];

    this.disableInputs(disabledInputs)

  }

  disableInputs(inputs) {
    for (var i = 0; i < inputs.length; i++) {
      this.profileForm.get(inputs[i]).disable()
    }
  }
  onSubmit() {
    for (var i in this.profileForm.controls) {
      this.profileForm.controls[i].markAsTouched();
    }
    if (this.profileForm.status == 'VALID') {
      this.save(this.profileForm.value)
    }
  }
  save(profileData) {

    this.profileService.updateProfilesData(profileData).subscribe((response: any) => {
      if (response.outcome.success === true) {
        swal.fire("Success", "", "success");
      } else {
        swal.fire("Error", this.settings.manageErrorMsg(response.outcome), "error");
      }
    },
      (error) => {
        this.errorMessage = this.errorTranslation.generic_error + " " + this.errorTranslation.try_again;
        swal.fire("Error", this.errorMessage, "error");
      })
  }

  navigationBack(){
    if (!this.routerExtService.getPreviousUrl() ||
      this.routerExtService.getPreviousUrl() == this.router.url ||
      this.routerExtService.getPreviousUrl().startsWith('/login')) {
      this.router.navigateByUrl('/user');
    } else {
      this.location.back();
    }
  }
}
